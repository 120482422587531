import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
/**
 * 获取当前语言的TaskInfo对象
 * @param taskRef Task对象ref
 * @param customLang 使用特定的语言,支持预览页切换语言
 * @returns 当前语言的TaskInfo对象 对象或null, 没有当前语言的配置则返回第一项
 */
const useTask = (taskRef, customLangRef) => {
    const { locale } = useI18n();
    const taskInfo = computed(() => {
        const target = taskRef.value.infos.find((item) => item.lang === (customLangRef?.value || locale.value));
        if (!target) {
            return taskRef.value.infos[0] || null;
        }
        return target;
    });
    return {
        taskInfo,
    };
};
export default useTask;
