import { defineComponent, onMounted, reactive, toRefs, watch, } from 'vue';
export default defineComponent({
    name: 'BaseTab',
    props: {
        modelValue: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        size: {
            // 切换语言tab使用sm
            type: String,
            required: false,
            default: 'normal',
        },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const state = reactive({
            left: 0,
            width: 0,
            tabRef: null,
            isLoaded: false,
        });
        const countPostion = (val) => {
            const dom = state.tabRef;
            if (!dom) {
                return;
            }
            const index = props.options.findIndex((item) => item.value === val);
            const target = dom.children[index];
            if (!target) {
                return;
            }
            state.left = target.offsetLeft;
            state.width = target.clientWidth;
        };
        const select = (val) => {
            if (props.modelValue === val) {
                return;
            }
            emit('update:modelValue', val);
            emit('change', val);
        };
        watch(() => props.modelValue, () => {
            countPostion(props.modelValue);
        });
        onMounted(() => {
            countPostion(props.modelValue);
            window.setTimeout(() => {
                state.isLoaded = true;
            }, 0);
        });
        return {
            select,
            ...toRefs(state),
        };
    },
});
