import { computed, defineComponent, ref, } from 'vue';
import BaseTable from '@/components/BaseTable/index.vue';
import BaseTableCol from '@/components/BaseTable/BaseTableCol';
import BaseButton from '@/components/BaseButton.vue';
import Backend from '@/core/Services/Backend';
import { TaskStatus } from '@/core/types/Task';
import Pager from '@/components/Pager.vue';
import usePager from '@/utils/usePager';
import useTask from '@/utils/useTask';
import { useRouter } from 'vue-router';
import Toast from '@/components/Toast';
import StorageServices from '@/core/Services/StorageServices';
import { useI18n } from 'vue-i18n';
import useDialog from '@/utils/useDialog';
import Dialog from '@/components/Dialog.vue';
import AuthQr from '@/components/AuthQr/index.vue';
import { getTableIndex } from '@/core/utils';
import { openExplorerFortx } from '@/core/utils/openExplorer';
import BaseTab from '@/components/BaseTab.vue';
import ButtonLayer from '@/components/ButtonLayer.vue';
export default defineComponent({
    name: 'BoardHome',
    components: {
        AuthQr,
        Dialog,
        BaseTable,
        BaseTableCol,
        BaseButton,
        Pager,
        BaseTab,
        ButtonLayer,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const tabOption = computed(() => [
            {
                value: 'drop',
                label: t('batch_drop'),
            },
            {
                value: 'nft',
                label: t('drop_nft'),
            },
        ]);
        const tabActive = ref('drop');
        // 批量空投列表
        const { list: dropList, total: dropTotal, page: dropPage, size: dropSize, loadCurrentPage: loadDropPage, loadedPage: dropLoadedPage, } = usePager(Backend.getDropList);
        // 批量空头NFT列表
        const { list: dropNftList, total: dropNftTotal, page: dropNftPage, size: dropNftSize, loadCurrentPage: loadDropNftPage, loadedPage: dropNftLoadedPage, } = usePager(Backend.getNftDropList);
        // 跳转到单条批量空投详情
        const toDropDetail = (chainType, txHash) => {
            openExplorerFortx(chainType, txHash); // 改跳转浏览器
        };
        // 空投任务列表
        const { list: taskList, total: taskTotal, page: taskPage, loadCurrentPage: loadTaskPage, size: taskSize, loadedPage: taskLoadedPage, } = usePager(Backend.getIncTaskList);
        const taskInfos = computed(() => {
            const taskListTemp = taskList.value.map((item) => {
                const { taskInfo } = useTask(ref(item));
                return taskInfo.value;
            });
            return taskListTemp;
        });
        // 发布任务
        const releaseTask = (id) => {
            Backend.releaseTask(id).then((res) => {
                if (res.status === 200 && res.data.error === 0) {
                    Toast.success(res.data.result);
                    loadTaskPage();
                }
            });
        };
        // 跳转到任务详情
        const toTaskDetail = (task) => {
            router.push({
                path: '/inc/dashboard/task',
                query: {
                    id: task.id,
                    chain: task.chain,
                },
            });
        };
        // 跳转到编辑任务
        const toEditTask = (task) => {
            StorageServices.storeEditingTask(task);
            router.push({
                path: '/inc/batch',
                query: {
                    id: task.id,
                },
            });
        };
        // 下架空投任务
        const soldOut = (id) => {
            Backend.cancelTask(id).then(() => {
                loadTaskPage();
            });
        };
        // 授权管理列表
        const { list: authorizationList, total: authorizationTotal, page: authorizationPage, loadCurrentPage: loadAuthorizationPage, size: authorizationSize, loadedPage: loadedAuthorizationPage, } = usePager(Backend.getAuthorizationList);
        const { isVisible: isCancelVisible, showDialog: showCancelDialog, closeDialog: closeCancelDialog, selectedTarget: cancelTarget, } = useDialog();
        const cancelAuthorization = (chainType, tokenHash) => {
            showCancelDialog({
                chainType,
                hash: tokenHash,
                amount: '0',
            });
        };
        const cancelSuccessHandler = () => {
            closeCancelDialog();
            loadAuthorizationPage();
        };
        return {
            t,
            tabOption,
            tabActive,
            dropList,
            dropTotal,
            dropPage,
            dropLoadedPage,
            dropSize,
            loadDropPage,
            toDropDetail,
            taskList,
            taskTotal,
            taskPage,
            taskLoadedPage,
            taskInfos,
            taskSize,
            loadTaskPage,
            toEditTask,
            releaseTask,
            toTaskDetail,
            authorizationList,
            authorizationTotal,
            authorizationPage,
            authorizationSize,
            loadedAuthorizationPage,
            loadAuthorizationPage,
            cancelAuthorization,
            isCancelVisible,
            showCancelDialog,
            cancelTarget,
            cancelSuccessHandler,
            closeCancelDialog,
            getTableIndex,
            soldOut,
            TaskStatus,
            dropNftList,
            dropNftTotal,
            dropNftPage,
            dropNftSize,
            loadDropNftPage,
            dropNftLoadedPage,
        };
    },
});
