import axios from 'axios';
import { onMounted, reactive, toRefs, ref, onBeforeUnmount, } from 'vue';
/**
 * 通用分页表格
 */
const usePager = (request, size = 10) => {
    const cancel = ref(null);
    const state = reactive({
        list: [],
        total: 0,
        page: 1,
        size,
        loadedPage: 0,
    });
    const loadCurrentPage = () => {
        if (cancel.value) {
            cancel.value.cancel();
        }
        cancel.value = axios.CancelToken.source();
        request(state.page, state.size, cancel.value.token)
            .then(({ count, record }) => {
            state.total = count;
            state.list = record;
            state.loadedPage = state.page;
        })
            .catch(() => {
            state.list = [];
        });
    };
    onMounted(() => {
        loadCurrentPage();
    });
    onBeforeUnmount(() => {
        if (cancel.value) {
            cancel.value.cancel();
        }
    });
    return {
        ...toRefs(state),
        loadCurrentPage,
    };
};
export default usePager;
