/**
 * @name Task
 * @author yuanzeyu
 * @date 2021/6/18
 * @desc 空投任务相关类型
 */
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus[TaskStatus["NotRelease"] = 0] = "NotRelease";
    TaskStatus[TaskStatus["BeforeStart"] = 2] = "BeforeStart";
    TaskStatus[TaskStatus["Active"] = 3] = "Active";
    TaskStatus[TaskStatus["End"] = 4] = "End"; // 显示 数据
})(TaskStatus || (TaskStatus = {}));
